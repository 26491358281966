body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
    font-family: 'coconbold';
    src: url('../fonts/cocon-bold-font-webfont.woff2') format('woff2'),
         url('../fonts/cocon-bold-font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
