.footer-container {
  margin-top: 40px;
  text-align: center;
  ul {
    padding: 0;
    list-style: none;
    li {
      border-bottom: 1px solid $blue;
      &:first-of-type {
        border-top: 1px solid $blue;
      }
    }
  }
  a {
    display: block;
    padding: 10px;
    color: $blue;
  }
  .img-urps {
    margin: auto;
    max-width: 60%;
  }
}
@media screen and (min-width:540px){
  .footer-container {
    ul {
      li {
        display: inline-block;
        border-bottom: 0;
        &:first-child{
          border-top: 0;
        }
        a {
          color: #000;
        }
      }
    }
    .img-urps {
      max-width: 250px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .footer-container {
    border-top: 1px solid $blue;
    padding: 15px 0;
    .row {
      align-items: center;
    }
    .img-urps {
      order: -1;
    }
  }
}
