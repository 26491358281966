/* Menu start */
.main-menu {
  overflow: hidden;
  transition: all .5s;
  &.open {
    height: auto;
    opacity: 1;
  }
  &.closed {
    height: 0;
    opacity: 0;
  }
  ul {
    padding: 0;
    list-style: none;
    background: $blue;
    li {
      a {
        display: block;
        padding: 15px 35px 15px 15px;
        color: $white;
        border-bottom: 1px solid $white;
        transition: all .2s;
        @include specificLeftArrow;
      }
      a:hover,
      a:focus {
        background: $green;
        text-decoration: none;
        color: $blue;
      }
      ul > li > a{
        padding-left: 50px;
        font-size: .9em;
      }
    }
  }
}
.menu-left {
  display: none;
}
@media screen and (min-width:992px){
  .burgerIcon {
    display: none;
  }
  .menu-left {
    position: sticky;
    top: 0;
    display: block;
    height: 100vh;
    padding: 0;
    background: $blue;
  }
}
/* Menu end */

/* Start User Guide title */
.user-guides-title-container-wrapper {
  @include specificBorderBottom;
  .user-guides-title-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    background: $green;
    overflow: hidden;
    max-width: 100%;
    .quick-link,
    h1.page-title {
      margin: 0;
      text-align: center;
    }

    .quick-link {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 40px;
      width: 40px;
      padding-left: 1px;
      height: 40px;
      color: $white;
      margin: 0 20px;
      text-decoration: none;
      line-height: 38px;
      background: $blue;
      border-radius: 50%;
      transition: all .2s;
      &:hover {
        background: $lightBlue;
      }
    }
  }
}
/* End User Guide title */

/* Start quick-link next (after content) */
.quick-link-content-next {
  display: block;
  padding: 15px 35px 15px 15px;
  margin-bottom: 20px;
  color: $blue;
  border: 1px solid $blue;
  @include specificLeftArrow;
  &:focus,
  &:hover {
    background-color: $green;
    text-decoration: none;
    color: $blue;
  }
}
/* End quick-link next (after content) */

/* Start quick links pre-footer */
.footer-quick-link-container {
  margin: 0 0 20px;
  &>div {
    align-items: center;
    padding: 1px;
  }
  .quick-link-footer {
    display: flex;
    align-items: center;
    padding: 15px;
    height: 100%;
    color: $white;
    background: $blue;
    &.footer-current,
    &:focus,
    &:hover {
      color: $blue;
      text-decoration: none;
      background: $green;
    }
    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}
/* End quick links pre-footer */

/* Start Prescriptions */
.prescriptions-container {

  a.is-active,
  a:hover,
  a:focus {
    background:$green;
    text-decoration: none;
  }
  .container-fluid {
    padding: 20px 0;
  }
  .prescriptions-title-container {
    @include specificBorderBottom;
  }
  h1.prescriptions-title {
    padding: 10px;
    font-family: 'coconbold';
    color: $blue;
    background: $green;
    font-weight: bold;
    font-size: 1.7em;
    text-align: center;
  }
  .prescription-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    button {
      border: 0;
      cursor: pointer;
    }
    .wrapper-utils button {
      transform: scale(1);
      transition: all .2s ease-in-out;
      margin: 0 2px;
      &:hover {
        transform: scale(1.1);
      }
    }
    .prescription-bookmark {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      background: url('../img/icon-favoris.svg') no-repeat;
      margin-left: auto;
      &.prescription-bookmark-bookmarked {
        background: url('../img/icon-favoris-moins.svg') no-repeat;
        animation-name: pulse;
        animation-duration: .4s;
        animation-iteration-count: 2;
      }
    }
    .print-icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      background: url('../img/icon-print.svg') no-repeat;
    }
  }
  .prescription-not-refunded {
    color: $red;
    font-style: italic;
  }
  .question-container {
    margin: 20px 0;
    .question-container-title {
      display: flex;
      align-items: center;
      padding: 15px;
      color: $white;
      background: $blue;
      h3 {
        font-weight: normal;
        margin: 0 15px;
      }
    }
    .question-container-subtitle {
      font-size: .8em;
      font-style: italic;
    }
    .question-container-display {
      position: relative;
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border: 2px solid $white;
      font-size: 1.2em;
      margin-left: auto;
      border-radius: 50%;
      background: $green;
      transition: all .2s;
      cursor: pointer;
      &:after {
        content:'>';
        position: absolute;
        top: 50%;
        left: 50%;
        color: $blue;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        transition: all .2s;
      }
      &:hover {
        background: $white;
      }
      &.question-container-display-open {
        &:after {
          content:'>';
          transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        }
      }
    }
    .question-container-content {
      overflow: hidden;
      border: 1px solid $blue;
      color: $blue;
      font-style: italic;
      transition: all .2s;
      .question-container-content-inner {
        padding: 15px;
      }
      ol {
        padding: 0;
        list-style-position: inside;
        li {
          margin-bottom: 10px;
        }
      }
      .hide-default-message {
        border:0;
        background:none;
        color: $grey;
        font-size: .8em;
        font-style: normal;
        &:focus,
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.question-container-close .question-container-content{
      height: 0;
      opacity: 0;
    }
    &.question-container-open .question-container-content{
      height: auto;
      opacity: 1;
    }
  }
  .similar-prescriptions {
    padding: 15px 0;
    font-weight: bold;
    a {
      display: inline-block;
      padding: 15px;
      margin: 2px;
      color: $white;
      font-weight: normal;
      background: $blue;
      &:first-child {
        margin-left: 0;
      }
      &:focus,
      &:hover {
        color: $blue;
        background: $green;
        text-decoration: none;
      }
    }
  }
  .prescriptions-filter {
    margin-bottom: 5px;
    padding-bottom: 40px;
    border-bottom: 1px solid $grey;
  }
  ul.prescriptions-list {
    padding: 0;
    list-style: none;
    li {
      border-bottom: 1px solid $blue;
      a {
        display: block;
        padding: 15px 35px 15px 15px;
        color: $blue;
        @include specificLeftArrow;
      }
    }
  }
}
/* End Prescriptions*/
/* Start tools */
.tools-container {
  .tools-container {
    padding: 0 20px;
  }
  .tools-title-container {
    @include specificBorderBottom;
  }
  h1.tools-title {
    padding: 10px;
    color: $blue;
    background: $green;
    font-weight: bold;
    font-size: 1.7em;
    text-align: center;
  }
  button.btn {
    display: inline-block;
    padding: 10px 15px;
    background: $blue;
    color: $white;
    border: 0;
    &:focus,
    &:hover {
      background: $green;
      color: $blue;
      cursor: pointer;
    }
  }
}
/* End tools */

.illustrations-container {
  .illustrations-container {
    padding: 0 20px;
  }
  .illustrations-title-container {
    @include specificBorderBottom;
  }
  h1.illustrations-title {
    padding: 10px;
    color: $blue;
    background: $green;
    font-weight: bold;
    font-size: 1.7em;
    text-align: center;
  }
}


.AppDisclamer {
  button {
    cursor: pointer;
    padding: 10px;
    color: $white;
    background: $blue;
    border:0;
    &:focus,
    &:hover {
      background: $green;
      color: $blue;
      cursor: pointer;
    }
  }
}


body.userguide_first .userguide_first {
  display:none;
}

.mea {
    color: $blue;
    font-weight:bold;
}


.user-guide-reminder {
  a {
    display: block;
    color:$white;
    padding:10px;
    background-color: $blue;
    text-align: center;
    &:focus,
    &:hover {
      background: $green;
      color: $blue;
    }
  }
}


#update_notification {
  display:none;
  margin:15px;
  color:$white;
  cursor:pointer;
  padding:10px;
  background-color: $blue;
  text-align: center;
  &:focus,
  &:hover {
    background: $green;
    color: $blue;
  }
  &.show {
    display: block;
  }
}

.hide {
  display:none
}



@media screen and (max-width:370px){
  .quick-link-next,
  .quick-link-prev {
    display:none;
  }
}


h1.page-title {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 15px 10px;
  color: $blue;
  font-family: 'coconbold';
  font-size: 1.6em;
  box-sizing: border-box;
}

.test {
  color:$blue
}


hr {
  width: 60%;
	border: 1px solid $blue;
  margin:30px auto;
}


.page-container {
  .page-title-container {
    @include specificBorderBottom;
  }
  h1.page-title {
    padding: 10px;
    font-family: 'coconbold';
    color: $blue;
    background: $green;
    font-weight: bold;
    font-size: 1.7em;
    text-align: center;
  }
}
