@mixin specificBorderBottom {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &:before {
    content: '';
    display: block;
    width: 98%;
    height: 5px;
    background: $lightBlue;
    order: 1;
  }
  &:after {
    order: 2;
    content: '';
    display: block;
    width: 98%;
    border-top: 10px solid $lightBlue;
    border-right: 2px solid $white;
    border-left: 69vw solid $white;
    @media screen and (min-width:1600px) {
      border-left: 1100px solid #fff;
    }
  }
}
@mixin specificLeftArrow {
  position: relative;
  &:after{
    content: '>';
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    width: 25px;
    padding-left: 1px;
    height: 25px;
    line-height: 22px;
    color: $white;
    background: $blue;
    border-radius: 50%;
  }
}
