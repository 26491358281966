body {
  background: #efefef;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 1600px;
  margin: 0 auto;
  background: $white;
}
h1 {
  margin: 0;
  color: $blue;
  font-weight: normal;
  a {
    color: $blue;
    text-decoration: none;
  }
}
h2 {
  color: $blue;
  font-weight: normal;
  font-size: 1.5em;
  margin-bottom: 20px;
}
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
.page-content {
  ul {
    list-style: none;
    padding: 0;
    li {
      color: $blue;
      &:before {
        content: '';
        position: relative;
        top: 2px;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        background: $blue;
      }
      ul {
        padding-left: 15px;
      }
    }
  }
  .redaction_conforme {
    font-style: italic;
  }
  .prescription_therapeutique {
    color: $red;
  }
}
.illustration {
  margin-bottom: 20px;
}
.illustration-prescription {
  max-width: 550px;
  margin: 40px auto 20px;
}
.illustration-row img {
  margin: 20px auto;
  display: block;
}
input[type=text] {
  display: block;
  width: 90%;
  margin: auto;
  padding: 10px;
  color: $grey;
  border: 1px solid $grey;
}
@media screen and (min-width:992px){
  .App {
    padding: 0;
  }
  .page-container-inner {
    padding: 0 40px;
  }
  .prescriptions-container .container-fluid {
    padding: 40px;
  }
}


.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-align-justify {
  text-align: justify;
}
