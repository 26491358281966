.header-container {
  .banner {
    display: flex;
    align-items: center;
    padding: 10px  0;
  }
  h1 {
    padding: 10px 20px;
    font-size: 1.2em;
    font-weight: normal;
  }
  .burgerIcon {
    width: 50px;
    height: 50px;
    padding: 10px;
    background: $blue;
    box-sizing: border-box;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      margin: 5px 0;
      background: $white;
      border-radius: 5px;
    }
    .bar {
      display: block;
      width: 100%;
      height: 4px;
      margin: 5px 0;
      background: $white;
      border-radius: 5px;
      transition: all .2s;
      &:first-child {
        margin-top: 4px;
      }
    }
  }
  .burgerIcon.open{
    position: relative;
    .bar, &:after {
      position: absolute;
      top: 18px;
      left: 10px;
      width: calc(100% - 20px);
      transform: rotate(45deg);
    }
    .bar:first-child {
      transform: rotate(-45deg);
      top: 21px;
      margin-top: 2px;
    }
  }
  .logo-urps {
    display: none;
  }
}
@media screen and (min-width:540px){
  .header-container {
    .logo-urps {
      display: block;
      flex: 0 0 auto;
      max-width: 200px;
      margin-left: auto;
    }
  }
}
@media screen and (min-width:992px){
  .header-container {
    h1 {
      padding: 10px 20px;
      font-size: 1.4em;
      font-weight: normal;
    }
    .main-menu {
      display: none;
    }
  }
}
